import React from 'react';
import { UnderlinedLinkInternal } from '../../../components/links';
import { Catchphrase } from '../../../components/typography';

const one = 'Close with confidence';
const two = 'Technology-enabled partner';
const three = 'A happy customer';

export const whatsInItIdeas = [
  {
    Image: {
      imageName: 'shaking-hands.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: 'We evaluate multiple financing options and determine a solution that sets your client up for success',
      label: one
    }
  },
  {
    Image: {
      imageName: 'k-at-laptop.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: 'Buyers can generate pre-approval letters on-demand from their phone and our Home Advisors are always available',
      label: two
    }
  },
  {
    Image: {
      imageName: 'happy-couple-smiling.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{three}</Catchphrase>,
      text: (
        <>
          Customers close with certainty and save money while doing it.{' '}
          <UnderlinedLinkInternal to="/reviews/">
            We have thousands of reviews to show for it
          </UnderlinedLinkInternal>
        </>
      ),
      label: three
    }
  }
];
