import { getImage, ImageQuery, ValueProps } from '@rategravity/marketing-components';
import React, { PropsWithChildren } from 'react';
import { whatsInItIdeas } from '../../../data/content/real-estate';
import { metadata } from '../../../data/metadata/images';
import { ValuePropsWrapper } from '../../wrappers';

export const imageStyle = {
  borderRadius: '50%',
  margin: 'auto',
  maxWidth: 250
};

export const Ideas = ({ imageData }: { imageData: ImageQuery }) => {
  const ideas = whatsInItIdeas.map(({ Image, data }) => {
    const img = getImage(imageData, Image.imageName, metadata, imageStyle);
    return {
      data,
      Image: img
    };
  });

  return (
    <ValuePropsWrapper>
      <ValueProps valueProps={ideas} header="What's in it for you" />
    </ValuePropsWrapper>
  );
};

export const WhatsInItForYou = ({ children }: PropsWithChildren<{}>) => (
  <section aria-label="What's in it for you">{children}</section>
);
