import React from 'react';
import { Catchphrase } from '../../../components/typography';
import BullseyeSVG from '../../../images/real-estate/bullseye.svg';
import HeartSVG from '../../../images/real-estate/heart.svg';
import OpenDoorSVG from '../../../images/real-estate/open-door.svg';

const one = 'Human Connection';
const two = 'Technology';
const three = 'Collaboration';

export const howItWorksREideas = [
  {
    Image: {
      svg: HeartSVG,
      imageName: 'heart.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: 'Your client creates a profile and is matched with an experienced Home Advisor to guide them (and support you) through the entire home buying process',
      label: one
    }
  },
  {
    Image: {
      svg: BullseyeSVG,
      imageName: 'bullseye.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: "Our technology evaluates your buyer's profile and goals to determine the optimal lender and financing plan to achieve a seamless closing",
      label: two
    }
  },
  {
    Image: {
      svg: OpenDoorSVG,
      imageName: 'open-door.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{three}</Catchphrase>,
      text: "It takes a team to meet your client's goals. Our Home Advisors are here to support you and your client day-and-night all the way through closing (we’re available by text, phone, or email)",
      label: three
    }
  }
];
