export const agentData = [
  {
    image: 'claudel.jpg',
    name: 'Claudel Frederique',
    title: 'from Western MA - Bentley Real Estate',
    quote:
      '"Own Up is able to really get aggressive with rates... they always jump in on the issues immediately... they never leave us hanging."'
  },
  {
    image: 'courtney.jpg',
    name: 'Courtney Lott',
    title: 'from Atlanta GA - Keller Williams',
    quote:
      '"Own Up worked tirelessly to ensure my clients were taken care of and received the best deal possible... they are responsive, dedicated, diligent."'
  },
  {
    image: 'brian.jpg',
    name: 'Brian Head',
    title: 'from San Antonio TX - Buckhorn Realty',
    quote:
      '"I have been amazed at the rates they have been able to get for my clients and myself on investment properties... the professionalism and responsiveness are unmatched in the mortgage industry... I have gotten this same feedback from my clients as well."'
  }
];
