import loadable from '@loadable/component';
import { ImageQuery, ImageWithContent } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Divider } from '../components/divider';
import { IntroImage } from '../components/intro-image';
import { Layout } from '../components/layout';
import { Ideas } from '../components/real-estate/whats-in-it-for-you';
import { ResponsiveSectionHeader } from '../components/typography';
import { agentData } from '../data/content/real-estate';
import GreenDotsBlob3SVG from '../images/green-dots-blob-3.svg';

const Agents = loadable(() => import('../components/real-estate/agents'), {
  resolveComponent: (components) => components.Agents
});

const HaveQuestions = loadable(() => import('../components/real-estate/have-questions'), {
  resolveComponent: (components) => components.HaveQuestions
});

const HowItWorks = loadable(() => import('../components/real-estate/how-it-works'), {
  resolveComponent: (components) => components.HowItWorks
});

const ResponsiveSpacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.ResponsiveSpacer
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

const WhatsInItForYou = loadable(() => import('../components/real-estate/whats-in-it-for-you'), {
  resolveComponent: (components) => components.WhatsInItForYou
});

export const query = graphql`
  {
    images: allFile(
      filter: { relativePath: { regex: "/real-estate//" }, extension: { ne: "svg" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export const RealEstate = ({ data }: { data: ImageQuery }) => {
  const realEstateIntroImage = (
    <IntroImage imageData={data} imageName="mike-and-patrick-in-park.png" />
  );
  return (
    <Layout>
      <ImageWithContent
        backgroundImage={GreenDotsBlob3SVG}
        Image={realEstateIntroImage}
        imagePosition="right"
        label="intro"
        sectionStyle={{ margin: '0 30px' }}
      >
        <ResponsiveSectionHeader variant="title">
          A partner for buyers and their agents.
        </ResponsiveSectionHeader>
      </ImageWithContent>
      <Divider />
      <Spacer />
      <HowItWorks />
      <HaveQuestions />
      <Spacer />
      <WhatsInItForYou>{Ideas({ imageData: data })}</WhatsInItForYou>
      <Divider />
      <ResponsiveSpacer />
      <Agents imageData={data} agentData={agentData} />
    </Layout>
  );
};

export default RealEstate;
